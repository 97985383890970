import React from "react";
import Container from "./container";
import FlexibleContent from "../components/flexible-content";
import SocialShare from "./social-share";

import * as styles from "../styles/components/post-main.module.css";

const PostMain = (props) => {

  const {
    title,
    content
  } = props;

  return (
    <div className={styles.root}>
      <Container>
        <div className={styles.content}>
          {content && (<FlexibleContent items={content} />)}
        </div>
        {title && (
          <div className={styles.sidebar}>
            <SocialShare title={title} />
          </div>
        )}
      </Container>
    </div>
  );
}

export default PostMain;
