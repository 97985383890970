// extracted by mini-css-extract-plugin
export var author = "post-page-header-module--author--51646";
export var back = "post-page-header-module--back--f28c6";
export var button = "post-page-header-module--button--97e57";
export var col = "post-page-header-module--col--f3149";
export var contentWrap = "post-page-header-module--contentWrap--452ef";
export var date = "post-page-header-module--date--7ecc7";
export var grid = "post-page-header-module--grid--f73b4";
export var image = "post-page-header-module--image--6d380";
export var logo = "post-page-header-module--logo--a98fb";
export var meta = "post-page-header-module--meta--3cd34";
export var noImg = "post-page-header-module--noImg--02def";
export var root = "post-page-header-module--root--278f9";
export var text = "post-page-header-module--text--42397";
export var title = "post-page-header-module--title--838a4";