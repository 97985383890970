import React from "react";
import Icon from "./icon";
import { useLocation } from '@reach/router';

import {
  TwitterShareButton,
  LinkedinShareButton,
  FacebookShareButton
} from "react-share";

import * as styles from "../styles/components/social-share.module.css";

const SocialShare = (props) => {

  const {
    title
  } = props;

  const url = useLocation().href;

  return (
    <div className={styles.root}>
      <div className={styles.heading}>Share this article</div>
      <ul className={styles.items}>
        <li>
          <button onClick={() => {navigator.clipboard.writeText(url)}}>
            <span className={styles.icon}><Icon symbol="link" /></span>
            Copy link
          </button>
        </li>
        <li>
          <TwitterShareButton 
            url={url}
            title={title}
          >
            <span className={styles.icon}><Icon symbol="twitter" /></span>
            Twitter
          </TwitterShareButton>
        </li>
        <li>
          <LinkedinShareButton 
            url={url}
            title={title}
          >
            <span className={styles.icon}><Icon symbol="linkedin" /></span>
            Linkedin
          </LinkedinShareButton>
        </li>
        <li>
          <FacebookShareButton 
            url={url}
            quote={title}
          >
            <span className={styles.icon}><Icon symbol="facebook" /></span>
            Facebook
          </FacebookShareButton>
        </li>
      </ul>
    </div>
  );
}

export default SocialShare;
