import React from "react";
import { graphql } from "gatsby";
import Layout from "../containers/layout";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";

import PageHeader from "../components/default-page-header";
import PostMain from "../components/post-main";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query PageTemplateQuery($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      id
      title
      subtitle
      thumbnail {
        ...SanityImage
        alt
      }
      flexibleContent {
        ... on SanityFcContentBlock {
          _key
          _type
          _rawContent
        }
        ... on SanityFcVideo {
          _key
          _type
          caption
          url
        }
      }
      seo {
        title
        description
        thumbnail {
          asset {
            _id
          }
          alt
        }
      }
    }
  }
`;

const PageTemplate = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  
  const page = (data || {}).page;

  const title = page.seo?.title || page.title;
  const description = page.seo?.description;
  const image = page.seo?.thumbnail || page.thumbnail;
  const autoSiteTitle = !page.seo?.title;

  return (
    <Layout>
      <Seo
        title={title}
        description={description}
        image={image}
        autoSiteTitle={autoSiteTitle}
      />

      {page.title && (<PageHeader title={page.title} subtitle={page.subtitle} image={page.thumbnail} />)}
      {page.flexibleContent && (<PostMain content={page.flexibleContent} />)}
    </Layout>
  );
};

export default PageTemplate;
