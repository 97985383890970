import React from "react";
import Container from "./container";
import { imageUrlFor } from "../lib/image-url";
import { cn, buildImageObj } from "../lib/helpers";
import { Link } from "gatsby";
import Icon from "./icon";

import * as styles from "../styles/components/post-page-header.module.css";

const PageHeader = (props) => {

  const {
    title,
    subtitle,
    image
  } = props;

  return (
    <div className={styles.root}>
      <div className={cn("grid", styles.grid)}>
        <div className={cn("col-12 ", (image && image.asset) ? "col-md-6" : styles.noImg, styles.col, styles.text)}>
          <Container>
            <div className={styles.contentWrap}>
              {title && (<h1 className={cn("h4", styles.title)}>{title}</h1>)}
              {subtitle && (<div className={styles.meta}>{subtitle}</div>)}
            </div>
          </Container>
        </div>
        {image && image.asset && (
          <div className={cn("col-12 col-md-6", styles.col, styles.image)}>
            <img
              src={imageUrlFor(buildImageObj(image))
                .auto("format")
                .url()}
              alt={image.alt}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default PageHeader;
